<template>
  <el-table
      :data="tableData"
      class="table"
      @expand-change='expandChange'
      ref="table"
      :header-cell-style="{
        background:'#f7f7f7',
        fontSize: '14px',
        fontFamily:'PingFang SC-Regular',
        fontWeight: 400,
        color: '#333333',
      }"
      style="width: 100%">
    <el-table-column label="序号" width="90" >
      <template slot-scope="scope">{{scope.$index+1}}</template>
    </el-table-column>

    <el-table-column label="专辑封面" width="120" v-slot="{row}" >
     <div style="display: flex;align-items:center">
       <el-image style="width: 56px; height: 56px" :src="coverFn(row)" fit="cover">
         <div slot="error" class="image-slot">
           <i class="el-icon-picture-outline"></i>
         </div>
       </el-image>
     </div>
    </el-table-column>

    <el-table-column label="专辑名称" width="220" prop="name" v-slot="{row}" >
      <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" :title="row.album_name">
        {{ row.album_name }}
      </div>
    </el-table-column>

    <el-table-column label="歌曲数" width="90" prop="applys_count" />

    <el-table-column width="180" label="操作时间" v-slot="{row}">
      <div>{{ moment(row.submit_at).format('YYYY-MM-DD HH:mm')}}</div>
    </el-table-column>

    <el-table-column label="操作人" :formatter="operator" />

    <el-table-column >

      <div slot-scope="scope" class="iconBox">
      {{issueType(scope.row)}}
      <el-tooltip v-if="scope.row.status == -2 || scope.row.status == -3 || scope.row.status == -4 "  class="item" effect="dark" :content="scope.row.false_desp" placement="bottom">
      <i  class="el-icon-warning-outline icon"></i>
    </el-tooltip>

     </div>
     <template slot="header">
       <el-dropdown @command="commandFn">
        <span style="color: #333333; font-weight: 400; font-size: 14px" >
               状态<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
         <el-dropdown-menu slot="dropdown">
           <el-dropdown-item  v-for="item in op" :key="item.value" :command="item.value" >{{item.label}}</el-dropdown-item>
         </el-dropdown-menu>
       </el-dropdown>
     </template>
    </el-table-column>

    <el-table-column
        v-slot="{row}"
        width="180"
        label="操作"
        >
      <div class="btnsBoxs">
        <div v-if="row.expand" class="pointer" @click="updateApply(row)">
          <span style="padding-right:5px">修改申请</span>
          <i v-if="row.isOpen" class="el-icon-arrow-up"></i>
          <i v-else class="el-icon-arrow-down"></i>
        </div>
        <div v-if="showRevoke(row)" @click="cancelFn(row)" class="pointer">撤销</div>
        <div v-if="showUpdate(row) && isShowUpdate" @click="updateFn(row)" class="pointer">修改</div>
        <div v-if="showCheck(row)" @click="checkFn(row)" class="pointer">查看</div>
        <div v-if="showDel(row)" @click="deleteFn(row)" class="pointer">删除</div>
      </div>
    </el-table-column>
    <el-table-column
        type="expand"
        width="0"
        v-slot="{row}">
        <div class="doBox">
          <div class="items"><span>修改版本</span><i>v{{row.cur_item.ver}}</i></div>
          <div class="items"><span>修改时间</span><i>{{row.cur_item.submit_at}}</i></div>
          <div class="items"><span>修改人</span><i>{{operator(row.cur_item)}}</i></div>
          <div  class="items"><span>状态</span><i>{{row.cur_item.status_tip}}</i></div>
          <div class="items">
            <span>操作</span>
            <div class="dd">
              <div v-if="showRevoke(row.cur_item)" @click="cancelFn(row)" class="pointer" style="font-size: 12px">撤销</div>
              <div v-if="showUpdate(row.cur_item)" @click="updateFn(row)" class="pointer" style="font-size: 12px">修改</div>
              <div v-if="showCheck(row.cur_item)" @click="checkFn(row)" class="pointer" style="font-size: 12px">查看</div>
<!--              <div v-if="showDel(row.cur_item)" @click="deleteFn(row)" class="pointer" style="font-size: 12px">删除</div>-->
            </div>
          </div>
        </div>
    </el-table-column>
  </el-table>
</template>

<script>
import {delAlbum} from "@/api/song"
import moment from "moment"
export default {
  props:{
    albumList: Array,
    issueStatusFn:Function,
    options:Array,
    userList:Array,
    isShowUpdate: {
      type: Boolean,
      default: true
    }
  },
  name: "albumList",
  data(){
    return {
      tableData: [],
      op:[],
      moment
    }
  },


  methods:{
    coverFn(row){
      let {cover} = row
      let url = ''
      if(cover){
        url = cover.location
      }
      return url
    },
    //撤销按钮
    showRevoke(row){
      let {status} = row
      let isShow = false
      if(status == 1 || status == 2 || status == -3 || status == -4){
        //待入库审核，待发行审核，发行审核未通过
        isShow = true
      }
      return isShow
    },
    //查看按钮
    showCheck(row){
      return !!row
      // let {status} = row
      // let isShow = false
      // if(status == 1 || status == 2 || status == 3 || status == -3 || status == -4 || status == -5){
      //   //待入库审核，待发行审核，发行中，发行审核未通过，发行失败，已下架
      //   isShow = true
      // }
      // return isShow
    },
    //修改按钮
    showUpdate(row){
      let {status,cur_item} = row
      let isShow = false
      if((status == 4 || status == 5 || status == -2 || status === -1) && cur_item == null){
        //待上架，已上架，入库审核失败
        isShow = true
      }
      return isShow
    },
    //删除按钮
    showDel(row){
      let {status} = row
      let isShow = false
      if(status == -2){
        //入库审核失败
        // isShow = true
      }
      return isShow
    },
    expandChange(row){
      if(row.isOpen){
        row.isOpen = false
      }else{
         row.isOpen = true
      }
    },
    updateApply(row){
      if(row.expand){
        this.$refs.table.toggleRowExpansion(row,!row.isOpen)
      }
    },

    deleteAlbum(id){
      delAlbum({}, {albumId: id}).then(() => {
        this.$parent.albumListFn()
        this.$refs['popover-' + id].showPopper = false
      })
    },
    operator(val){
      let name = '--'
      this.userList.forEach(item=>{
        if(item.id == val.operator){
          name = item.nickName
        }
      })
      return name
    },

    cancelFn(row){
      this.$emit('cancelFn',row)
    },
    checkFn(row){
      this.$emit('checkFn',row, 1)
    },
    updateFn(row){
      this.$emit('updateFn',row,1)
    },
    deleteFn(row){
      this.$emit('deleteFn',row)
    },
    commandFn(command){
      this.issueStatusFn(command)
    },
  // 发行状态筛选
    issueType(val){
      let {status} = val
      if(status == 0){
        return '草稿'
      }else if(status == 1) {
        return '待曲库审核'
      }else if(status == 2) {
        return '待发行审核'
      }else if(status == 3) {
        return '发行中'
      }else if(status == 4) {
        return '待上架'
      }else if(status == 5) {
        return '已上架'
      }else if(status == -1) {
        return '已撤销'
      }else if(status == -2) {
        return '曲库审核不通过'
      }else if(status == -3) {
        return '发行审核不通过'
      }else if(status == -4) {
        return '发行失败'
      }else if(status == -5) {
        return '发行成功'
      }else{
        return ''
      }
    }
  },
  mounted() {
    this.tableData = this.albumList
    this.op = this.options
  },
  watch:{
    options(val){
    this.op = val
    },
     "albumList":{
          handler:function(val){
            this.tableData = val
          },
          deep:true,
       }
  }
}
</script>

<style scoped lang="scss">
.hideExpend{
  display: none;
}
.image-slot{
  width: 56px;
  height: 56px;
  background: #f5f7fa;
  font-size: 40px;
  color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnsBoxs{
  display: flex;
  align-items: center;
}
.pointer{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FF477E;
  font-size: 14px;
  margin-right: 20px;
}
.doBox{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #fbfbfb;
  padding: 18px 0 14px 0;
  margin-left: 24px;
}
.items{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .items span{
    color: #999;
    font-size: 12px;
    padding-bottom: 11px;
  }
  .items i{
    color: #666;
    font-size: 12px;
    font-style: normal;
  }
  .dd{
    display: flex;
  }
  .iconBox{
    display: flex;
    align-items: center;
  }
  .icon{
    font-size: 16px;
    cursor: pointer;
    margin-left: 6px;
  }
</style>
<style>
.expand .el-table__expand-column {
    display: none;
}
.el-table__expand-column .cell {
  display: none;
}
.el-table__expanded-cell{
  padding: 0 !important;
}

</style>
