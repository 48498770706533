<template>
<div class="works">
    <LeftMenu current="songManage"></LeftMenu>
    <div class="main" :class="{applyList:$route.meta.active}">
      <div class="manage">
    <div v-if="draftCount>0" class="header" >
      <div class="l"><img alt="加载失败" src="../../assets/songManage.png">您有{{draftCount}}个草稿待发布</div>
      <div class="r" @click="$router.push('/draftList')">查看</div>
    </div>
    <div class="content">
      <div class="title">发行申请</div>
      <el-divider></el-divider>
      <div class="empower">
        <div class="isEmpower">
          <div @click="isWarrant = 0"  :class="{active:isWarrant === 0}">未发行</div>
          <div @click="isWarrant = 1" :class="{active:isWarrant === 1}">已发行</div>
        </div>
          <div class="editBtn" @click="$router.push('/songUploadRecord')" style="padding: 0 20px;">
            <span>歌曲文件上传记录</span>
          </div>
        <div @click="$router.push('/updateApplyList')" class="editBtn">
          <span>修改记录</span>
        </div>
        <div class="issue" @click="$router.push('/issue')" v-show="hasPermission(6)">发布歌曲</div>
      </div>
      <div class="songAndAlbum">
        <ul class="radio">
          <li :class="{active:isSong === 0}" @click="isSong = 0">
            专辑 {{albumTotal === 0 ? '':albumTotal}}
            <div></div>
          </li>
          <li @click="isSong = 1" :class="{active:isSong === 1}">
            歌曲 {{songTotal === 0 ? "":songTotal}}
            <div></div>
          </li>
        </ul>
        <div class="search">
          <el-input v-show="isSong == 0" prefix-icon="el-icon-search" v-model="album_name" :placeholder="`请输入专辑名称关键字`" @input="lookupFn"></el-input>
          <el-input v-show="isSong == 1" prefix-icon="el-icon-search" v-model="song_name" :placeholder="`请输入歌曲名称关键字`" @input="lookupFn"></el-input>
        </div>
      </div>
      <album-list
          v-loading="albumFlag"
        @cancelFn="cancelFn"
        @checkFn="checkFn"
        @updateFn="updateFn"
        @deleteFn="deleteFn"
        :is-show-update="isWarrant === 0"
        :issueStatusFn="issueStatusFn"
        :userList="userList"
        :options="options"
        :albumList="albumArr"
        v-if="isSong === 0" >
      </album-list>
      <song-list
          v-loading="songFlag"
        @cancelFn="cancelFn"
        @checkFn="checkFn"
        @updateFn="updateFn"
        @deleteFn="deleteFn"
        :is-show-update="isWarrant === 0"
        :issueStatusFn="issueStatusFn"
        :userList="userList"
        :options="options"
        :albumList="songArr"
        v-if="isSong === 1" >
      </song-list>
      <div class="footer-pagination">
        <el-pagination
            :current-page="paging.cur_page"
            :page-size="paging.size"
            @current-change="currentChange"
            background
            layout="prev, pager, next, slot"
            hide-on-single-page
            :total="paging.total">
          <ul class="alone">
            <li class="totalPage">
              共 {{paging.max_page}} 页
            </li>
            <li>到第
              <el-input v-model.number="inputVal"></el-input>
            </li>
            <li>
              <el-button  @click="butfn()">确定</el-button>
            </li>
          </ul>
        </el-pagination>
      </div>
    </div>
  </div>
    </div>
    <div class="dialogDiv">
      <el-dialog
        :close-on-click-modal="false"
        title="操作确认"
        :visible.sync="popShow"
        height="360px"
        width="480px"
        center
      >
        <div class="popBody">{{popData.title}}</div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="popShow = false">取 消</el-button>
          <el-button :loading='submitLoading' type="primary" @click="albumCancelFn" class="determine">确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getAlbumList,getSongList,getUserList,getDraftCount,albumCancel} from '@/api/issue/'
import AlbumList from "./components/applyList/albumList";
import SongList from "./components/applyList/songList";
import LeftMenu from '../../components/LeftMenu'
// import toast from '../../utils/toast';
let timer ;
//已发行：待上架、已上架的数据
const STATUS_ED = [4,5,-5]
const STATUS = [1,2,3,-1,-2,-3,-4]
const SIZE = 10
export default {
  components: {SongList, AlbumList,LeftMenu},
  data(){

    return {
      songFlag:false,
      albumFlag:false,
      options:[
        {label:'全部',value:'1,2,3,-1,-2,-3,-4'},
          {label:'待曲库审核',value: '1'},
          {label:'待发行审核',value: '2'},
          {label:'发行中',value: '3'},
          {label:'已撤销',value: '-1'},
          {label:'曲库审核不通过',value: '-2'},
          {label:'发行审核不通过',value: '-3'},
          {label:'发行失败',value: '-4'}
      ],
      submitLoading:false,
      popShow:false,
      popData:{
        album_id:'',
        album_name:'',
        title:''
      },
      isWarrant:0,
      isSong:0,
      inputVal:1,
      userList:[],
      issueStatus:'0,1',
      dialogVisible:true,
      song_name:'',
      album_name:'',
      status_ids:STATUS,
      albumArr:[],
      songArr:[],
      paging:{
        total:0,
        page:1,
        size:SIZE
      },
      albumTotal:0,
      songTotal:0,
      draftCount:0,
      albumSuccessCount:0,
      albumRunningCount:0,
      songSuccessCount:0,
      songRunningCount:0
    }
  },
  created() {
    getDraftCount({},'album','draftCount').then(res=>{
      if(res.code == 0){
        let {draftCount,albumSuccessCount,albumRunningCount,songSuccessCount,songRunningCount} = res.data
        this.draftCount = draftCount;
        this.albumSuccessCount = albumSuccessCount;
        this.albumRunningCount = albumRunningCount;
        this.songSuccessCount = songSuccessCount;
        this.songRunningCount = songRunningCount;
        this.albumTotal = this.albumRunningCount
        this.songTotal = this.songRunningCount
      }
    })
    getUserList().then((res) => {
        if (res.code == 0) {
          this.userList = res.data;
        }
      });
    this.getAlbumListFn()
    this.getSongListFn()

  },
  methods:{
    hasPermission(id) {
      return !this.isCompany || this.isAdmin || this.permissionIds.find(e => typeof id === 'object' ? id.find(f => e === f.permissionId) : e === id)
    },
    albumCancelFn(){
      this.submitLoading = true
      albumCancel({album_id:this.popData.album_id},'album','cancel').then(res=>{
        if(res.code == 0){
          this.popShow = false
          this.submitLoading = false
          let list = []
          if(this.isSong){
            list = this.songArr
          }else{
            list = this.albumArr
          }
          list.forEach(item=>{
            if(item.album_id == this.popData.album_id){
              if(item.cur_item){
                item.cur_item.status = -1
              }else{
                item.status = -1
              }
            }
          })
          this.$message({
          message: '撤销成功',
          type: 'success'
        });
        }
      }).catch(()=>{
        this.submitLoading = false
      })

    },
    butfn(){
      this.paging.page = this.inputVal
      if(this.isSong){
        this.getSongListFn()
      }else {
        this.getAlbumListFn ()
      }
    },
    currentChange(val){
      this.paging.page = val

      if(this.isSong){
        this.getSongListFn()
      }else {
        this.getAlbumListFn()
      }
    },
  //  专辑列表
    getAlbumListFn (){
      this.albumFlag = true;
      let p = {
        page:this.paging.page,
        size:SIZE,
        status_ids:this.status_ids,
        album_name:this.album_name,
        pub_type:this.isWarrant
      }
      getAlbumList(p,'album','list').then(res=>{
        this.albumFlag = false;
       if(res.code === 0){
        let { data,total,cur_page,max_page,per_page } = res.data
        data.forEach(item=>{
          if(item.cur_item){
            item.expand = true
          }
        })
        this.paging.total = total
        this.paging.cur_page = cur_page
        this.paging.max_page = max_page
        this.paging.per_page = per_page
        this.albumArr = data
       }
      })
    },

    //重置分页参数
    resetPaginationData(){
      this.song_name = ''
      this.album_name = ''
      this.paging.page = 1
      this.paging.total = 0
    },

    cancelFn(row){
      let {album_id,album_name = '',name = ''} = row
      let title =''
      if(this.isSong){
        title = `请确认是否撤回歌曲 “${name}”的发行`
      }else{
        title = `请确认是否撤回专辑 “${album_name}”的发行`
      }
      this.popData = {
        album_id,
        title,
        album_name
      }
      this.popShow = true
    },
     checkFn(row, type = 0){
       if(type === 1)  this.$router.push(`/query/${row.album_id}/${row.ver}`)
        else  this.$router.push(`/query/${row.album_id}/${row.songs_album.ver}/${row.id}`)
    },
     deleteFn(row){
      console.log('删除',row)
    },
     updateFn(row,type = 0){
      if(type === 1){
        this.$router.push(`/issue/${row.album_id}/${row.ver}`)
      }else {
        this.$router.push(`/issue/${row.album_id}/${row.songs_album.ver}/${row.id}`)
      }

    },

  //  修改发行状态
    issueStatusFn(val){
      this.paging.page = 1
      this.status_ids = val.split(',')
      if(this.isSong){
        this.getSongListFn()
      }else {
        this.getAlbumListFn ()
      }
    },
  //  歌曲列表
    getSongListFn(){
      this.songFlag = true;
      let p = {
        page:this.paging.page,
        size:SIZE,
        status_ids:this.status_ids,
        name:this.song_name,
        pub_type:this.isWarrant
      }
    getSongList(p,'song','list').then(res=>{
      this.songFlag = false;
      if(res.code === 0){
        let { data,total,cur_page,max_page,per_page } = res.data
        data.forEach(item=>{
          if(item.cur_item){
            item.expand = true
          }
        })
        this.paging.total = total
        this.paging.cur_page = cur_page
        this.paging.max_page = max_page
        this.paging.per_page = per_page
        this.songArr = data
       }
    })
    },
  // 查找
    lookupFn(){
      clearInterval(timer)
      if (this.isSong) {
          timer = setTimeout(()=>{
            this.paging.page = 1
            this.paging.total = 0
            this.getSongListFn()
          },800)
      }else {
        timer = setTimeout(()=>{
          this.paging.page = 1
          this.paging.total = 0
          this.getAlbumListFn()
        },800)
      }
    },

  },
  watch:{
    isWarrant(val){
      if(val == 0){
        //未发行

        this.albumTotal = this.albumRunningCount
        this.songTotal = this.songRunningCount

        this.status_ids = STATUS
        this.options = [
          {label:'全部',value:'1,2,3,-1,-2,-3,-4'},
          {label:'待曲库审核',value: '1'},
          {label:'待发行审核',value: '2'},
          {label:'发行中',value: '3'},
          {label:'已撤销',value: '-1'},
          {label:'曲库审核不通过',value: '-2'},
          {label:'发行审核不通过',value: '-3'},
          {label:'发行失败',value: '-4'}
        ]
      }else{
        //已发行
        this.albumTotal = this.albumSuccessCount
        this.songTotal = this.songSuccessCount
        this.status_ids = STATUS_ED
        this.options = [
          {label:'全部',value:'4,5'},
          {label:'待上架',value: '4'},
          {label:'已上架',value: '5'},
          {label:'已下架',value: '-5'}
        ]
      }

      this.resetPaginationData()
      if(this.isSong){
        this.getSongListFn()
      }else {
        this.getAlbumListFn ()
      }
    },

    isSong(val){
      this.resetPaginationData()
      if(val){
        //歌曲
        this.getSongListFn()
      }else {
        //专辑
        this.getAlbumListFn()
      }
    }
  },
  computed: {
    permissionIds(){
      return this.$store.state.roleInfo && this.$store.state.roleInfo.subIds ? this.$store.state.roleInfo.subIds : []
    },
    isCompany(){
      return this.$store.state.usermsg.webLoginType === 1
    },
    isAdmin(){
      return this.$store.state.usermsg.userCompanyVo.managerUser === 1
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/works';

.applyList{
  padding: 0;
  background-color: #f7f7f7;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dialogDiv{
    .el-button{
      width: 160px;
      height: 40px;
      border-radius: 3px;
    }
    .determine{
      margin-left: 25px;

    }
    ::v-deep .el-dialog{
      border-radius: 10px;
    }
    .popBody{
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #333333;
      font-size: 14px;
    }
   ::v-deep .el-dialog__footer{
      padding:0 0  40px 0;
    }
  }

.manage {
  .header {
    height: 50px;
    background: #ffeecd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: PingFang SC-Regular;
    font-weight: 400;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 20px;

    .l {
      color: #775834;

      img {
        vertical-align: middle;
        margin-right: 12px;
        width: 22px;
        height: 22px;
      }
    }

    .r {
      width: 80px;
      height: 28px;
      background: #ffffff;
      border-radius: 2px;
      line-height: 28px;
      text-align: center;
      color: #333333;
      cursor: pointer;
    }
  }
  .content {
    box-sizing: border-box;
    background-color: #ffffff;
    width: 100%;
    min-height: 700px;
    padding: 30px 40px;
    .title{
      color: #333333;
      font-size: 14px;
    }

    .empower {
      display: flex;
      align-items: center;
      margin-top: 32px;
      .editBtn{
         margin-right: 16px;
          min-width: 116px;
          height: 40px;
          background: rgba(255,0,77,0.1);
          border-radius: 3px;
          font-size: 14px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          i{
            color: #FF004D;
            font-size: 14px;
            font-weight: 400;
          }
          span{
            color: #FF004D;
            font-weight: 400;
            font-size: 14px;
          }
        }
      .isEmpower {
        margin-right: auto;
        width: 203px;
        height: 40px;
        line-height: 40px;
        background: #f4f4f4;
        border-radius: 2px;
        border: 1px solid #F4F4F4;
        font-size: 15px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
        display: flex;
        div {
          flex: 1;
          text-align: center;
          cursor: pointer;
        }

        .active {
          background-color: #ffffff;
          font-weight: 500;
          color: #ff004d;
          font-family: PingFang SC-Medium;
          cursor: default;
        }
      }

      .issue {
        width: 116px;
        height: 40px;
        background: #ff004d;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFang SC-Medium;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }

    .songAndAlbum {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      .radio {
        font-size: 15px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #999999;
        display: flex;
        li {
          margin-right: 44px;
          cursor: pointer;
          text-align: center;
          display: flex;
          flex-direction: column;
           align-items: center;
          div {
            width: 44px;
            height: 2px;
            background-color: #FFFFFF;
            margin-top:14px;
          }
        }

        .active {
          cursor: default;
          color: #ff004d;
          div {
           background-color: #ff004d;
          }
        }
      }
      .search{
        ::v-deep .el-input__inner{
          width: 275px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #e5e5e5;
          border-radius: 3px;
        }
      }

    }
    .footer-pagination {
      margin: 39px 0 39px 0;
      display: flex;
      justify-content: center;

      .alone {
        display: inline-flex;
        height: 30px;
        align-items: center;
        font-size: 12px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #555555;

        .totalPage {
          width: 40px;
          margin: 0 17px 0 6px;
        }

        ::v-deep .el-input {
          .el-input__inner {
            width: 30px;
            height: 30px;
            border: 1px solid #e1e0e0;
            border-radius: 2px;
          }
        }
        .el-button{
          margin-left:6px;
          width: 48px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #e1e0e0;
          border-radius: 2px;
        }
      }

      .alone > li {
        display: inline-block;
      }
    }
  }

}
</style>
